<template>
  <transition
    enter-active-class="alertEnter animated zoomInUp"
    leave-active-class="alertLeave animated fadeOut faster"
    appear
    mode="out-in"
  >
    <div class="alert-parent">
      <!-- TEXT -->
      <div class="text">
        <!-- TEXT 1 -->
        <div class="text-1">
          {{ introVerb }}
        </div>

        <!-- TEXT 2 -->
        <div class="text-2" ref="text-2">
          {{ followerName }}
        </div>

        <!-- TEXT 3 -->
        <div class="text-3">
          {{ followTitle }}
        </div>

        <!-- BLOOD SPATTER -->
        <div class="blood-spatter animated zoomIn">
          <img class="layer-1" src="./assets/img/layer-3@2x.png" />
          <img class="layer-2" src="./assets/img/layer-4@2x.png" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from "vuex";
  import fitty from "fitty";

  export default {
    props: {
      taskObject: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        introVerbs: ["Starring...", "Introducing...", "Debuting...", "Guest Starring..."],
        options: {
          minSize: 500,
          maxSize: 12,
        },
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        isBroadcasting: "global/getBroadcasting",
        latestFollower: "twitch/getLatestFollower",
      }),
      followerName() {
        if (this.taskObject && this.taskObject.data.arg && this.taskObject.data.arg !== "<name>") {
          return this.taskObject.data.arg;
        }
        return this.latestFollower;
      },
      followTitle() {
        return this.$store.state.global.followTitles[0];
      },
      introVerb() {
        const rand = Math.floor(Math.random() * this.introVerbs.length) + 0;
        return this.introVerbs[rand];
      },
      isDiscreetModeEnabled() {
        return !!this.getActiveTask("alerts", "Discreet Mode");
      },
    },
    mounted() {
      this.$store.dispatch("twitch/setFollowerCount"); // Increment sub number by ONE.

      // PLAY MUSIC
      if (!this.isDiscreetModeEnabled) {
        this.$playSound("cryptkeeper-jam", { interrupt: true, volume: 0.3 });
      }

      this.$store.dispatch("tasks/runTasks", [
        { uuid: "bb5398fa-660b-44e6-85d4-5720bf9879f4" }, // long static
      ]);

      this.$store.dispatch("tasks/runTasksRemotely", [
        { uuid: "156ccfdd-d055-4b7b-b695-c917641ddfde", canceled: false }, // hide posters
        { uuid: "c388392f-1931-4481-8c7e-99a0755b642a", canceled: false }, // pause media poster
      ]);

      if (!this.isDiscreetModeEnabled) {
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "541d6fd1-327f-485f-b8bd-757504aef24c", canceled: true, tasksInParallel: [] }, // remove player fullscreen
          { uuid: "be5681a7-aff1-4df5-a855-30237ecf62ee", canceled: false }, // mute
        ]);
      }

      fitty(this.$refs["text-2"]);

      setTimeout(() => this.$playSound("slice", { volume: 0.4, interrupt: true }), 400);

      const randomScream = "girl-scream-" + Math.floor(Math.random() * 1) + 6;
      setTimeout(() => this.$playSound(randomScream, { volume: 0.2, interrupt: true }), 400);
    },
    beforeDestroy() {
      this.$store.dispatch("tasks/runTasksRemotely", [
        { uuid: "156ccfdd-d055-4b7b-b695-c917641ddfde", canceled: true }, // hide posters
      ]);

      // Pop it off the array.
      if (this.isBroadcasting) {
        this.$store.commit("global/REMOVE_LAST_FOLLOW_TITLE"); // discard previous followTitle
      }

      if (!this.isDiscreetModeEnabled) {
        // Don't need this with silent disco - things have changed.
        this.$store.dispatch("tasks/runTasksRemotely", [
          { uuid: "be5681a7-aff1-4df5-a855-30237ecf62ee", canceled: true }, // mute
        ]);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .alert-parent {
    display: flex;
    align-items: center;
    // justify-content: center;
    position: absolute;
    left: 15px;
    top: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(40 100);
    padding-bottom: rem-calc(220);
    // background: rgba(black, 0.5);
    // z-index: 10;
    z-index: 2;
  }

  //-----------------------------------------------------------------
  // TEXT
  //-----------------------------------------------------------------

  .alert-parent .text {
    position: relative;
    width: rem-calc(760);
    // border: 2px dashed black;
    font-family: "Avenir Next Condensed";
    font-weight: 600;
    line-height: 0.9;
    text-transform: uppercase;
    color: white;
    text-shadow: 0 20px 60px rgba(black, 1);
    // transform: perspective(300px) rotateY(5deg) skew(0, 1deg);
    animation: float 3s infinite linear;

    @keyframes float {
      from {
        transform: rotate(0deg) translate3d(3px, 0, 0) rotate(0deg) perspective(300px) rotateY(5deg)
          skew(0, 1deg);
      }
      to {
        transform: rotate(360deg) translate3d(3px, 0, 0) rotate(-360deg) perspective(300px)
          rotateY(5deg) skew(0, 1deg);
      }
    }
  }

  //-----------------------------------------------------------------
  // TEXT 1
  //-----------------------------------------------------------------

  .text-1 {
    font-size: rem-calc(70);
    // animation: float 3s infinite;
    color: $primary;
  }

  .text-2 {
    font-size: rem-calc(120);
  }

  .text-3 {
    font-size: rem-calc(45);
    color: rgba(white, 0.8);
  }

  //-----------------------------------------------------------------
  // BLOOD SPATTER
  //-----------------------------------------------------------------

  .blood-spatter {
    position: absolute;
    top: rem-calc(-160);
    left: rem-calc(-50);
    width: rem-calc(600);
    height: rem-calc(600);
    // border: 1px dashed black;
    animation-duration: 300ms;
    animation-delay: 650ms;
    z-index: -1;

    .layer-1,
    .layer-2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .blood-spatter .layer-1 {
    animation: blood-float-1 3s infinite linear;

    @keyframes blood-float-1 {
      from {
        transform: rotate(0deg) translate3d(3px, 0, 0) rotate(0deg);
      }
      to {
        transform: rotate(360deg) translate3d(3px, 0, 0) rotate(-360deg);
      }
    }
  }

  .blood-spatter .layer-2 {
    animation: blood-float-2 3s infinite linear;

    @keyframes blood-float-2 {
      from {
        transform: rotate(0deg) translate3d(6px, 0, 0) rotate(0deg);
      }
      to {
        transform: rotate(360deg) translate3d(6px, 0, 0) rotate(-360deg);
      }
    }
  }
</style>
